import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
  theme,
  Typography,
} from "antd";
import CompanyLogo from "../photos/CompanyLogo.png";
import { useNavigate } from "react-router-dom";
import "./DefaultLayout.css";
const { Header, Content, Footer } = Layout;

const DefaultLayout = ({ children }) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const navigate = useNavigate();
  const [login, setLogin] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    business: "",
    country: "",
  });
  const handleClick = (e) => {
    setSelectedKey(e.key);
    navigate(`${e.key}`);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      business: "",
      country: null,
    });
  };
  const items = new Array(3).fill(null).map((_, index) => ({
    key: String(index + 1),
  }));
  items.push({
    key: "4",
    label: "Tools", // Label for the "Option" menu item
    children: [
      {
        key: "/LMS",
        label: "Lead Management System",
      },
      {
        key: "/Appt",
        label: "Health Care Appointment System",
      },
    ],
  });
  items.push({
    key: "5",
    label: "Plans and Pricing",
    children: [
      {
        key: "/payment",
        label: "Price",
      },
    ],
  });
  items.push({
    key: "6",
    label: "Integration",
    children: [
      {
        key: "/WhatsApp",
        label: "Campaigns",
      },
    ],
  });

  const Country = ["India", "Dubai"];
  const countryOptions = Country.map((country) => ({
    value: country,
    label: country,
  }));

  const handleLogin = () => {
    setLogin(true);
  };
  const handleClose = () => {
    setLogin(false);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "white",
            },
          },
        }}
      >
        <Layout>
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              width: "100%",
              boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
              backgroundColor: "white",
              padding: 0, // Remove default padding
            }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "auto",
                padding: "0 10px",
              }}
            >
              <a
                href="/"
                alt="go to home page"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={CompanyLogo}
                  alt="CompanyLogo"
                  style={{
                    maxWidth: "100%", // Scale image
                    maxHeight: "85px", // Adjust height as needed
                    marginTop: "0", // Reset margin
                  }}
                />
              </a>

              <Menu
                theme="light"
                mode="horizontal"
                items={items}
                onClick={handleClick}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  display: "flex",
                  justifyContent: "center",
                }}
              />

              <Button
                style={{
                  width: "10rem",
                  borderRadius: "4rem",
                  textAlign: "center",
                  fontSize: "large",
                  height: "3rem",
                  background: "#c54927",
                  color: "white",
                }}
                onClick={handleLogin}
              >
                Register
              </Button>
            </div>
          </Header>
          <div>{children}</div>

          <Footer
            style={{
              textAlign: "center",
              background: "linear-gradient(250deg, #f95d09, #4470e0)",
              color: "white",
              width: "100%",
            }}
          >
            ©{new Date().getFullYear()}Copyright Reverse By Digileads
          </Footer>
        </Layout>
      </ConfigProvider>

      <Modal
        visible={login}
        onCancel={handleClose}
        footer={[
          <Button
            key="submit"
            style={{
              backgroundColor: "#d22a5a",
              color: "white",
              borderRadius: "1rem",
              marginTop: "1rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form onSubmit={handleSubmit}>
          {" "}
          {/* Form onSubmit handler */}
          <Row>
            <Col xl={24}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Name
              </Typography>
              <Input
                style={{ width: "100%" }}
                onChange={handleChange}
                value={formData.name}
                name="name"
              />
            </Col>
            <Col xl={24}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Email
              </Typography>
              <Input
                onChange={handleChange}
                value={formData.email}
                name="email"
              />
            </Col>
            <Col xl={24}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Phone No
              </Typography>
              <Input
                onChange={handleChange}
                value={formData.phoneNumber}
                name="phoneNumber"
              />
            </Col>
            <Col xl={24}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Business
              </Typography>
              <Input
                onChange={handleChange}
                value={formData.business}
                name="business"
              />
            </Col>
            <Col xl={24}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Country
              </Typography>
              <Select
                style={{ width: "100%" }}
                options={countryOptions}
                onChange={(value) =>
                  handleChange({ target: { name: "country", value } })
                }
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default DefaultLayout;
