import React, { useState } from "react";
import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import BookAppt from "../../components/photos/BookAppt.png";
import ApptList from "../../components/photos/ApptList.png";
import listOfUserinAppt from "../../components/photos/listOfUserinAppt.png";
import ApptUser from "../../components/photos/ApptUser.png";
import addhospital from "../../components/photos/addhospital.png";
import assignSpecaility from "../../components/photos/assignSpecaility.png";
import addArea from "../../components/photos/addAreainappt.png";
import addCity from "../../components/photos/AddCityinappt.png";
import Login from "../../components/photos/LoginAppt.png";
import reportinAppt from "../../components/photos/reportinAppt.png";
import { Card, Carousel, Col, Collapse, Row, Typography } from "antd";
import addAreainappt from "../../components/photos/addAreainappt.png";
import Specaility from "../../components/photos/assignSpecaility.png";
import Addhospital from "../../components/photos/addhospital.png";
import AddDoctor from "../../components/photos/addDoctor.png";
import AddCity from "../../components/photos/AddCityinappt.png";
import "./Appt.css";

import {
  LeftOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";

const { Panel } = Collapse;
const Appt = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState({});

  const handleCollapseChange = (key) => {
    setExpandedKeys((prevKeys) => ({
      ...prevKeys,
      [key]: !prevKeys[key],
    }));
  };
  const reviews = [
    {
      Heading: "Add hospital",
      img: Addhospital,
    },
    {
      Heading: "Add City",
      img: AddCity,
    },

    {
      Heading: "Add Area",
      img: addAreainappt,
    },
    {
      Heading: "Add Services",
      img: Specaility,
    },
    {
      Heading: "Add Doctor",
      img: AddDoctor,
    },
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 2 : reviews.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % reviews.length);
  };
  return (
    <>
      <DefaultLayout>
        <Card style={{ background: "#bdeac8ba" }}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#c54927",
                }}
              >
                Health Care Appointment System (HAS)
              </h1>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "1rem",
                    }}
                  >
                    <img
                      src={Login}
                      alt="Login Icon"
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                  </Card>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "1rem",
                      height: "100%",
                    }}
                  >
                    <ul style={{ justifyContent: "center" }}>
                      <li
                        style={{ fontWeight: "bolder", fontFamily: "Poppins" }}
                      >
                        Open the doctor Appointment System Platform
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Navigate to the Doctor Appointment System website or app
                        where you are registered as a user.
                      </Typography>
                      <li
                        style={{ fontWeight: "bolder", fontFamily: "Poppins" }}
                      >
                        Enter Your Email
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        . In the login form, find the field labeled "Email" or
                        "Username."
                      </Typography>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        . Enter the email address that you registered with
                        during user creation.
                      </Typography>
                      <li
                        style={{ fontWeight: "bolder", fontFamily: "Poppins" }}
                      >
                        Enter Your Password
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        . In the field labeled "Password," type in the password
                        you set when you registered.
                      </Typography>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        .Ensure your password is entered correctly, as passwords
                        are usually case-sensitive.
                      </Typography>
                      <li
                        style={{ fontWeight: "bolder", fontFamily: "Poppins" }}
                      >
                        Click Sign In
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        . After entering both your email and password, click on
                        the "Sign In" button.
                      </Typography>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        . This will authenticate your credentials and grant
                        access to your LMS account.
                      </Typography>
                    </ul>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
        </Card>
        <Card style={{ background: "white" }}>
          <Row
            gutter={[35, 35]}
            justify="center"
            style={{
              paddingLeft: "35px",
              paddingRight: "35px",
            }}
          >
            <Col xs={24} sm={10} md={10} xl={20}>
              <Card
                className="container"
                style={{
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                  padding: "1rem",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <Carousel
                  arrows={true}
                  infinite={true}
                  autoplay
                  autoplaySpeed={4000}
                  slidesToShow={1}
                  slidesToScroll={1}
                  className="container"
                >
                  <div>
                    <Row align="middle">
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={Addhospital}
                          alt="Addhospital Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={AddCity}
                          alt="AddCity Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row align="middle">
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={addAreainappt}
                          alt="addAreainappt Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row align="middle">
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={Specaility}
                          alt="Specaility Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row align="middle">
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={AddDoctor}
                          alt="AddDoctor Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Carousel>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <center>
                <h4 style={{ fontFamily: "Poppins" }}>Data Management</h4>
              </center>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
                className="container"
              >
                Data management involves organizing and maintaining essential
                information within a system. It includes adding, updating, and
                associating various entities such as hospitals, doctors,
                available time slots, specialties, areas, and cities. These
                features streamline the management of healthcare services,
                making it easier to maintain accurate and up-to-date information
                for scheduling, resource allocation, and service delivery.
              </Typography>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                    }}
                  >
                    <h5 style={{ fontFamily: "Poppins" }}>Add your Hospital</h5>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      The "Add Hospital" feature allows administrators to
                      register new hospitals or medical facilities in the
                      system. It involves capturing details such as the
                      hospital's name, address, contact information, and the
                      services offered. This includes hospital information
                      collection, where details like the hospital name, address,
                      contact number, email, and services are captured, as well
                      as facility details, which allow for adding information
                      about departments, equipment, or specialties available at
                      the hospital.
                    </Typography>
                    <h5 style={{ fontFamily: "Poppins" }}>Add Doctor</h5>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      The "Add Doctor" feature enables administrators to add new
                      doctors to the system. It includes collecting information
                      about the doctor's qualifications, specialties, contact
                      details, and availability. This involves gathering details
                      such as the doctor’s name, qualifications, specialties,
                      contact information, and professional experience.
                      Additionally, the feature allows assigning the doctor to
                      one or more specialties (e.g., cardiology, pediatrics) and
                      setting up available time slots for appointments.
                      effectiveness.
                    </Typography>
                  </Card>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "0.5rem",
                    }}
                  >
                    <h5 style={{ fontFamily: "Poppins" }}>Add Slots</h5>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      The "Add Slots" feature allows administrators to define
                      time slots for appointments or services, setting up the
                      availability for doctors, facilities, or specific
                      services. This ensures that slots are well-organized and
                      easily managed or booked by patients and staff. Similarly,
                      the "Add City" feature enables administrators to add new
                      cities to the system by capturing details such as the
                      city’s name, state, country, or region. This feature helps
                      categorize and organize healthcare services
                      geographically, making it easier for users to locate
                      hospitals and services in specific areas
                    </Typography>
                    <h5 style={{ fontFamily: "Poppins" }}>Add City,Area</h5>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      The "Add City" feature allows administrators to add cities
                      with details like name, state, and country, helping
                      organize healthcare services geographically for easier
                      location access. The "Area" feature further refines this
                      by categorizing specific neighborhoods within cities,
                      aiding in the localized management of healthcare
                      facilities and region-specific services.
                    </Typography>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            background: "#bdeac8ba",
          }}
        >
          <Row className="container" justify="center" align="middle">
            <Col xs={24} sm={10} md={10} xl={20}>
              <Card
                className="container"
                style={{
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                }}
              >
                <Carousel
                  arrows={true}
                  infinite={true}
                  autoplay
                  autoplaySpeed={4000}
                  slidesToShow={1}
                  slidesToScroll={1}
                  className="container"
                >
                  <div>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={BookAppt}
                          alt="Appt Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={ApptList}
                          alt="ApptList Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Carousel>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <center>
                <Typography
                  style={{
                    fontWeight: 700,
                    marginTop: "2rem",

                    fontFamily: "Poppins",
                  }}
                >
                  Book an appointment and Appointment List
                </Typography>
              </center>

              <Typography
                style={{
                  fontSize: "1rem",
                  fontFamily: "Poppins",
                }}
                className="container"
              >
                Book an Appointment allows users to schedule a meeting or
                consultation with a professional or service provider (e.g.,
                doctor, consultant, stylist). This functionality typically
                involves selecting a preferred date, time, and service type. It
                may also include details such as the user's personal
                information, reason for the appointment, and any special
                requests. The system then checks availability and confirms the
                appointment, sometimes sending a confirmation email or SMS.
              </Typography>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "1rem",
                    }}
                  >
                    <ul>
                      <li style={{ fontWeight: "bolder" }}>
                        Date and Time Selection:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows users to choose the desired appointment date and
                        time.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Service Selection:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Users can pick from available services (e.g., medical
                        consultation, hair styling)
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Provider Selection:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Option to choose a specific professional (e.g., a
                        particular doctor).
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        User Details Collection:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Gathers information like name, contact details, and
                        appointment purpose.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Cancellation and Rescheduling:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        May offer options to cancel or change the appointment.
                      </Typography>
                    </ul>
                  </Card>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "1rem",
                      height: "100%",
                    }}
                  >
                    <ul>
                      <li style={{ fontWeight: "bolder" }}>
                        Filtering and Sorting:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Users can filter appointments by date, status, or
                        service type and sort them as needed.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Search Functionality:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows searching for specific appointments using
                        keywords.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Status Indicators:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Shows appointment status (confirmed, rescheduled,
                        canceled).
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Appointment Details View:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Users can click to view detailed information for each
                        appointment.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>Action Buttons:</li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Options to cancel, reschedule, or update appointment
                        details.
                      </Typography>
                    </ul>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            background: "white",
          }}
        >
          <Row className="container" justify="center" align="middle">
            <Col xs={24} sm={10} md={10} xl={20}>
              <Card
                className="container"
                style={{
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                  padding: "1rem",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <Carousel
                  arrows={true}
                  infinite={true}
                  autoplay
                  autoplaySpeed={4000}
                  slidesToShow={1}
                  slidesToScroll={1}
                  className="container"
                >
                  <div>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={ApptUser}
                          alt="ApptUser Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={24}
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={listOfUserinAppt}
                          alt="listOfUserinAppt Icon"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Carousel>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <center>
                <Typography
                  style={{
                    marginTop: "2rem",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                  }}
                >
                  Add User and User List
                </Typography>
              </center>

              <Typography
                style={{
                  fontSize: "1rem",
                  fontFamily: "Poppins",
                }}
                className="container"
              >
                The "Add User" feature allows administrators to add new users to
                the system based on different roles, such as Admin, Doctor,
                Hospital, or Front Office staff. Each role may have distinct
                permissions and access rights tailored to their
                responsibilities. When adding a user, the system collects
                relevant information (e.g., name, contact details, role-specific
                attributes), assigns the appropriate role, and optionally sets
                up login credentials for system access.
              </Typography>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "1rem", // Add padding for better spacing
                }}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%", // Use full width for responsiveness
                      // padding: "1rem",
                    }}
                  >
                    <ul>
                      <li style={{ fontWeight: "bolder" }}>
                        Role-Based Addition
                      </li>
                      <Typography
                        style={{ fontFamily: "Poppins", marginLeft: "1rem" }}
                      >
                        Gathers essential details, including, (e.g., Name,
                        email, and phone number)
                      </Typography>
                      <li style={{ marginRight: "1rem", marginLeft: "1rem" }}>
                        <span style={{ fontWeight: "bolder" }}>Admin :</span>
                        Full access to manage the system, users, appointments,
                        and configurations.
                      </li>
                      <li style={{ marginLeft: "1rem" }}>
                        <span style={{ fontWeight: "bolder" }}>Doctor :</span>
                        Can view and manage their appointments, patient details,
                        and medical records.
                      </li>
                      <li style={{ marginLeft: "1rem" }}>
                        <span style={{ fontWeight: "bolder" }}>Hospital :</span>
                        May have access to multiple facilities' operations and
                        hospital-wide data.
                      </li>
                      <li style={{ marginLeft: "1rem" }}>
                        <span style={{ fontWeight: "bolder" }}>
                          Front Office :
                        </span>
                        Handles appointment scheduling, patient registration,
                        and basic administrative tasks.
                      </li>

                      <li style={{ fontWeight: "bolder" }}>
                        User Information Collection:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Gathers essential details, including, (e.g.,Name, email,
                        and phone number)
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Access Control Setup:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Assigns different permissions based on the user role.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Login Credentials Creation:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Option to create login details (username and password)
                        for accessing the system.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Activation Status:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows setting the initial user status (active,
                        inactive).
                      </Typography>
                    </ul>
                  </Card>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Card
                    className="container"
                    style={{
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)",
                      width: "100%",
                      padding: "1rem",
                      height: "100%",
                    }}
                  >
                    <ul>
                      <li style={{ fontWeight: "bolder" }}>
                        Filtering and Sorting:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Users can be filtered by role (Admin, Doctor, Hospital,
                        Front Office), status (active, inactive), or other
                        criteria.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Search Functionality:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows searching for users by name, email, or role.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        User Details View:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Click to view detailed information about each user,
                        including role-specific details.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Edit and Update Options:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows updating user details, changing roles, or
                        modifying access rights.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Edit and Update Options:
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Allows updating user details, changing roles, or
                        modifying access rights.
                      </Typography>
                      <li style={{ fontWeight: "bolder" }}>
                        Activation/Deactivation :
                      </li>
                      <Typography style={{ fontFamily: "Poppins" }}>
                        Enables administrators to activate or deactivate user
                        accounts.
                      </Typography>
                    </ul>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            background: "#bdeac8ba",
          }}
        >
          <Row>
            <Col
              xs={24}
              sm={10}
              md={16}
              lg={16}
              xl={24}
              style={{
                padding: "1rem",
              }}
            >
              <Card
                className="container"
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  objectFit: "contain", // Ensures the image maintains its aspect ratio
                }}
              >
                <img
                  src={reportinAppt}
                  alt="reportinAppt Icon"
                  style={{
                    width: "100%",
                  }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <center>
                <Typography style={{ fontWeight: 700, fontFamily: "Poppins" }}>
                  Generate Appointment Report
                </Typography>
              </center>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
                className="container"
              >
                The "Generate Appointment Report" feature allows users to create
                detailed reports of appointments within the system. These
                reports provide insights into the appointment data and can be
                used for analysis, record-keeping, or administrative purposes.
                The reports can be generated based on various filters, enabling
                users to view appointments according to specific criteria. The
                system supports exporting the report in different formats, such
                as PDF and Excel (XLS), for easy sharing and offline access.
              </Typography>
            </Col>
          </Row>
        </Card>
        <Card style={{ background: "white" }}>
          <Row gutter={[35, 35]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Frequently Asked Questions
              </h1>
              <div
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel1")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel1"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>How do I book an appointment?</h2>
                        {expandedKeys["panel1"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      To book an appointment, select the service or doctor,
                      choose a convenient date and time slot from the available
                      options, and confirm your details. You’ll receive a
                      confirmation via email or SMS.
                    </Typography>
                  </Panel>
                </Collapse>

                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel2")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel2"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>Can I reschedule or cancel my appointment?</h2>
                        {expandedKeys["panel2"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      Yes, appointments can be rescheduled or canceled up to 24
                      hours before the scheduled time. Go to "Appointments List"
                      in your account, select the appointment, and choose the
                      reschedule or cancel option.
                    </Typography>
                  </Panel>
                </Collapse>

                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel3")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel3"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>Will I get a reminder for my appointment?</h2>
                        {expandedKeys["panel3"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      Yes, you’ll receive an automated reminder via email or SMS
                      24 hours before your scheduled appointment time.
                    </Typography>
                  </Panel>
                </Collapse>
                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel3")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel3"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>Is booking an appointment easy?</h2>
                        {expandedKeys["panel3"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      Yes, booking is quick and simple! Just select your service
                      or doctor, pick an available date and time, and confirm.
                      The process takes only a few steps.
                    </Typography>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Card>
      </DefaultLayout>
    </>
  );
};
export default Appt;
