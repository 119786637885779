import React from "react";
import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import "./Integration.css";
import { Card, Col, Row, Typography } from "antd";
import whatsapp from "../../components/photos/whatsapp.png";
import sendgrid from "../../components/photos/sendgrid.png";
import twilioSMS from "../../components/photos/twilioSMS.png";
import "./Integration.css";
const Integration = () => {
  return (
    <>
      <DefaultLayout>
        <Card style={{ marginTop: "0.5rem", backgroundColor: "#f9ede9" }}>
          <Row className="container">
            <Col span={24}>
              <Typography.Title
                level={2} // Use a specific title level for consistency
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  background: "linear-gradient(250deg, #f95d09, #4470e0)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold", // Ensure font weight matches your design
                }}
              >
                Campaigns
              </Typography.Title>
            </Col>

            <Col
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "5rem" }}
            >
              <Card
                style={{
                  margin: "10px",
                }}
              >
                <img
                  src={whatsapp}
                  alt="WhatsApp icon"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <Typography style={{ fontWeight: "bold", marginLeft: "3%" }}>
                  WhatsApp gateways
                </Typography>
                <Typography style={{ marginLeft: "3%" }}>
                  WhatsApp gateways in our Lead Management System allow for
                  direct engagement with leads via popular messaging platforms.
                  They streamline communication, enabling real-time interactions
                  and personalized marketing efforts to enhance customer
                  relationships.
                </Typography>
              </Card>
            </Col>

            <Col
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "5rem" }}
            >
              <Card
                style={{
                  margin: "10px",
                }}
              >
                <img
                  src={sendgrid}
                  alt="SendGrid icon"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography style={{ fontWeight: "bold", marginLeft: "3%" }}>
                  Email gateways
                </Typography>
                <Typography style={{ marginLeft: "3%" }}>
                  Email gateways in our Lead Management System facilitate
                  seamless communication by managing the flow of emails between
                  your organization and potential leads. They ensure secure
                  delivery, filter spam, and enforce compliance policies,
                  thereby enhancing the effectiveness of your emails
                </Typography>
              </Card>
            </Col>

            <Col
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "5rem" }}
            >
              <Card
                style={{
                  margin: "10px",
                }}
              >
                <img
                  src={twilioSMS}
                  alt="TwilioSMS icon"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography style={{ fontWeight: "bold", marginLeft: "3%" }}>
                  SMS gateways
                </Typography>
                <Typography style={{ marginLeft: "3%" }}>
                  SMS gateways in our Lead Management System enable efficient
                  communication with leads through instant text messaging. They
                  facilitate timely updates and alerts, ensuring that your
                  messages reach potential customers securely and reliably and
                  improves overall lead management efficiency.
                </Typography>
              </Card>
            </Col>
          </Row>
        </Card>
      </DefaultLayout>
    </>
  );
};
export default Integration;
