import {
  Card,
  Col,
  Row,
  Typography,
  Switch,
  ConfigProvider,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import React, { useState } from "react";
import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import { use } from "framer-motion/m";
// import "/Payemt.css";
const Payment = () => {
  const [getform, setGetform] = useState(null);
  const [check, setCheck] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    business: "",
    country: "",
  });

  const [getmonthStarter, setgetmonthStarter] = useState(`${200}`);
  const [getGrowth, setgetGrowth] = useState(280);
  const [getProfessional, setProfessional] = useState(380);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlemanageToggle = () => {
    setCheck(!check);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      business: "",
      country: null,
    });
  };

  const handleForm = (data) => {
    setGetform(true);
  };
  const handleClose = () => {
    setGetform(false); // Close the dialog
  };
  const Country = ["India", "Dubai"];
  const countryOptions = Country.map((country) => ({
    value: country,
    label: country,
  }));

  const handleChangesvalues = (checked) => {
    if (checked) {
      setgetmonthStarter(12 * getmonthStarter);
      setProfessional(12 * getProfessional);
      setgetGrowth(12 * getGrowth);
    } else {
      setgetmonthStarter(200);
      setProfessional(380);
      setgetGrowth(280);
    }
  };
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              colorPrimary: "#d22a5a",
              colorPrimaryHover: "#d22a5a9e",
            },
          },
        }}
      >
        <DefaultLayout>
          <Card style={{ marginTop: "0.5rem", backgroundColor: "#f9ede9" }}>
            <Row className="container">
              <Col xs={24} sm={20} md={24} lg={20} xl={24}>
                <center>
                  <Typography.Title>Choose your plan.</Typography.Title>
                  <Typography style={{ fontSize: "1.5rem", marginTop: "2rem" }}>
                    30-Day Risk-Free Money Back Guarantee. Cancel Anytime.
                  </Typography>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4rem",
                      }}
                    >
                      <Typography
                        style={{
                          marginRight: "1rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        Monthly
                      </Typography>
                      <Switch onChange={handleChangesvalues} />
                      <Typography
                        style={{
                          marginLeft: "1rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        Yearly
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4rem",
                        marginLeft: "2rem",
                      }}
                    >
                      <Typography
                        style={{
                          marginRight: "1rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        LMS
                      </Typography>
                      <Switch onChange={handlemanageToggle} />
                      <Typography
                        style={{
                          marginLeft: "1rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        HAS
                      </Typography>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "2rem",
                      marginLeft: "2rem",
                    }}
                  >
                    <Typography
                      style={{
                        marginRight: "1rem",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      LMS
                    </Typography>
                    <Switch onChange={handlemanageToggle} />
                    <Typography
                      style={{
                        marginLeft: "1rem",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      HAS
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "1.2rem",
                    }}
                  >
                    <Typography
                      style={{
                        marginRight: "1rem",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      Monthly
                    </Typography>
                    <Switch onChange={handleChangesvalues} />
                    <Typography
                      style={{
                        marginLeft: "1rem",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      Yearly
                    </Typography>
                  </div>
                </center>
              </Col>
              {check ? (
                <>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "radial-gradient(ellipse at 0.7% 3.4%, rgb(164, 231, 192) 0%, rgb(255, 255, 255) 90%)",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1.5rem", textAlign: "center" }}
                      >
                        Free Trial for HAS
                      </Typography>
                      <Typography.Title
                        style={{ color: "green", textAlign: "center" }}
                      >
                        ₹ 0
                      </Typography.Title>
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          fontFamily: "Poppins",
                        }}
                      >
                        Test drive HAS FREE for 30 days! Build your course,
                        generate leads.
                      </Typography>
                      <ul>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Engage with your audience
                        </li>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Drive sales
                        </li>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Join a vibrant community of successful educators
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Start for free")}
                        >
                          Start For Free
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          marginTop: "0%",
                          textAlign: "center",
                        }}
                      >
                        Starter for HAS
                      </Typography>
                      <Typography.Title
                        style={{ color: "darkblue", textAlign: "center" }}
                      >
                        ₹ {getmonthStarter}
                      </Typography.Title>
                      <Typography style={{ fontSize: "1.5rem" }}>
                        Access the core features to easily launch your online
                        business.
                      </Typography>
                      <ul>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Turn your skills into income
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Easy setup for selling your expertis
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Begin making money from knowledge.
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Changed to alignItems for vertical alignment
                            backgroundColor: "darkblue",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Get Starter Plan")}
                        >
                          Get Starter Plan
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "linear-gradient(109.6deg, rgb(232, 252, 252) 11.2%, rgb(252, 215, 134) 56.2%, rgb(247, 124, 124) 100.2%)",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          marginTop: "0%",
                          textAlign: "center",
                        }}
                      >
                        Professional for HAS
                      </Typography>
                      <Typography.Title
                        style={{ color: "orange", textAlign: "center" }}
                      >
                        ₹ {getProfessional}
                      </Typography.Title>
                      <Typography style={{ fontSize: "1.5rem" }}>
                        Unlock your business's potential with our all-in-one
                        tool
                      </Typography>
                      <ul>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Harness the power of our all-in-one tool.
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Streamline your processes.
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Centralize everything in one place.
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Changed to alignItems for vertical alignment
                            backgroundColor: "orange",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Get Professional Plan")}
                        >
                          Get Professional Plan
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "radial-gradient(ellipse at 0.7% 3.4%, rgb(164, 231, 192) 0%, rgb(255, 255, 255) 90%)",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1.5rem", textAlign: "center" }}
                      >
                        Free Trial for LMS
                      </Typography>
                      <Typography.Title
                        style={{ color: "green", textAlign: "center" }}
                      >
                        ₹ 0
                      </Typography.Title>
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          fontFamily: "Poppins",
                        }}
                      >
                        Test drive LMS FREE for 30 days! Build your course,
                        generate leads.
                      </Typography>
                      <ul>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Engage with your audience
                        </li>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Drive sales
                        </li>
                        <li
                          style={{ fontSize: "1.5rem", fontFamily: "Poppins" }}
                        >
                          Join a vibrant community of successful educators
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Start for free")}
                        >
                          Start For Free
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          marginTop: "0%",
                          textAlign: "center",
                        }}
                      >
                        Starter for LMS
                      </Typography>
                      <Typography.Title
                        style={{ color: "darkblue", textAlign: "center" }}
                      >
                        ₹ {getmonthStarter}
                      </Typography.Title>
                      <Typography style={{ fontSize: "1.5rem" }}>
                        Access the core features to easily launch your online
                        business.
                      </Typography>
                      <ul>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Turn your skills into income
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Easy setup for selling your expertis
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Begin making money from knowledge.
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Changed to alignItems for vertical alignment
                            backgroundColor: "darkblue",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Get Starter Plan")}
                        >
                          Get Starter Plan
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={8}
                    style={{ marginTop: "2rem" }}
                  >
                    <Card
                      style={{
                        margin: "10px",
                        maxWidth: "500px",
                        background:
                          "linear-gradient(109.6deg, rgb(232, 252, 252) 11.2%, rgb(252, 215, 134) 56.2%, rgb(247, 124, 124) 100.2%)",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          marginTop: "0%",
                          textAlign: "center",
                        }}
                      >
                        Professional for LMS
                      </Typography>
                      <Typography.Title
                        style={{ color: "orange", textAlign: "center" }}
                      >
                        ₹ {getProfessional}
                      </Typography.Title>
                      <Typography style={{ fontSize: "1.5rem" }}>
                        Unlock your business's potential with our all-in-one
                        tool
                      </Typography>
                      <ul>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Harness the power of our all-in-one tool.
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Streamline your processes.
                        </li>
                        <li
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          Centralize everything in one place.
                        </li>
                      </ul>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Changed to alignItems for vertical alignment
                            backgroundColor: "orange",
                            color: "white",
                            borderRadius: "2rem",
                            margin: "5%",
                            height: "2.5rem",
                          }}
                          onClick={() => handleForm("Get Professional Plan")}
                        >
                          Get Professional Plan
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </>
              )}

              {/* <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={8}
                style={{ marginTop: "2rem" }}
              >
                <Card
                  style={{
                    margin: "10px",
                    maxWidth: "500px",
                    background:
                      "radial-gradient(ellipse at 0.7% 3.4%, rgb(164, 231, 192) 0%, rgb(255, 255, 255) 90%)",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      textAlign: "center",
                    }}
                  >
                    Free Trial
                  </Typography>
                  <Typography.Title
                    style={{ color: "green", textAlign: "center" }}
                  >
                    ₹ 0
                  </Typography.Title>
                  <Typography
                    style={{
                      fontSize: "1.5rem",

                      fontFamily: "Poppins",
                    }}
                  >
                    Test drive LMS FREE for 30 days! Build your course, generate
                    leads.
                  </Typography>

                  <ul>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Engage with your audience
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Drive sales
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Join a vibrant community of successful educators
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", // Changed to alignItems for vertical alignment
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "2rem",
                        margin: "5%",
                        height: "2.5rem",
                      }}
                      onClick={() => handleForm("Start for free")}
                    >
                      Start For Free
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={8}
                style={{ marginTop: "2rem" }}
              >
                <Card
                  style={{
                    margin: "10px",
                    maxWidth: "500px",
                    background:
                      "radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginTop: "0%",
                      textAlign: "center",
                    }}
                  >
                    Starter
                  </Typography>
                  <Typography.Title
                    style={{ color: "darkblue", textAlign: "center" }}
                  >
                    ₹ {getmonthStarter}
                  </Typography.Title>
                  <Typography style={{ fontSize: "1.5rem" }}>
                    Access the core features to easily launch your online
                    business.
                  </Typography>
                  <ul>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Turn your skills into income
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Easy setup for selling your expertis
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Begin making money from knowledge.
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", // Changed to alignItems for vertical alignment
                        backgroundColor: "darkblue",
                        color: "white",
                        borderRadius: "2rem",
                        margin: "5%",
                        height: "2.5rem",
                      }}
                      onClick={() => handleForm("Get Starter Plan")}
                    >
                      Get Starter Plan
                    </Button>
                  </div>
                </Card>
              </Col>

              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={8}
                style={{ marginTop: "2rem" }}
              >
                <Card
                  style={{
                    margin: "10px",
                    maxWidth: "500px",
                    background:
                      "linear-gradient(109.6deg, rgb(232, 252, 252) 11.2%, rgb(252, 215, 134) 56.2%, rgb(247, 124, 124) 100.2%)",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      marginTop: "0%",
                      textAlign: "center",
                    }}
                  >
                    Professional
                  </Typography>
                  <Typography.Title
                    style={{ color: "orange", textAlign: "center" }}
                  >
                    ₹ {getProfessional}
                  </Typography.Title>
                  <Typography style={{ fontSize: "1.5rem" }}>
                    Unlock your business's potential with our all-in-one tool
                  </Typography>
                  <ul>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Harness the power of our all-in-one tool.
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Streamline your processes.
                    </li>
                    <li
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Centralize everything in one place.
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", // Changed to alignItems for vertical alignment
                        backgroundColor: "orange",
                        color: "white",
                        borderRadius: "2rem",
                        margin: "5%",
                        height: "2.5rem",
                      }}
                      onClick={() => handleForm("Get Professional Plan")}
                    >
                      Get Professional Plan
                    </Button>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </Card>

          <Modal
            visible={getform}
            onCancel={handleClose}
            footer={[
              <Button
                key="submit"
                style={{
                  backgroundColor: "#d22a5a",
                  color: "white",
                  borderRadius: "1rem",
                  marginTop: "1rem",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>,
            ]}
          >
            <Form onSubmit={handleSubmit}>
              {/* Form onSubmit handler */}
              <Row>
                <Col xl={24}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Name
                  </Typography>
                  <Input
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    value={formData.name}
                    name="name"
                  />
                </Col>
                <Col xl={24}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Email
                  </Typography>
                  <Input
                    onChange={handleChange}
                    value={formData.email}
                    name="email"
                  />
                </Col>
                <Col xl={24}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Phone No
                  </Typography>
                  <Input
                    onChange={handleChange}
                    value={formData.phoneNumber}
                    name="phoneNumber"
                  />
                </Col>
                <Col xl={24}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Business
                  </Typography>
                  <Input
                    onChange={handleChange}
                    value={formData.business}
                    name="business"
                  />
                </Col>
                <Col xl={24}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Country
                  </Typography>
                  <Select
                    style={{ width: "100%" }}
                    options={countryOptions}
                    onChange={(value) =>
                      handleChange({ target: { name: "country", value } })
                    }
                  />
                </Col>
              </Row>
            </Form>
          </Modal>
        </DefaultLayout>
      </ConfigProvider>
    </>
  );
};
export default Payment;
