import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Divider,
  Flex,
  Row,
  Typography,
} from "antd";
import Lmsicon from "../../components/photos/NewLMS(logo).png";
import hospitalicon from "../../components/photos/hospitalicon.png";
import Patientsicons from "../../components/photos/Patientsicons.png";
import Pharmaciesicon from "../../components/photos/Pharmaciesicon.png";
import AdministrativeStaff from "../../components/photos/AdministrativeStaff.png";
import doctorhomepage from "../../components/photos/BookanAppt.jpg";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashBoard1 from "../../components/photos/DashBoard.png";
import ApptDashbord from "../../components/photos/ApptDashbord.png";
import { Footer } from "antd/es/layout/layout";
import LeadCapture from "../../components/photos/icon-1.png";
import MultiChannelSupport from "../../components/photos/icon-2.png";
import ComprehensiveDashboards from "../../components/photos/icon-3.png";
import EasytoUse from "../../components/photos/icon-4.png";
import EasytoconnectHIS from "../../components/photos/icon-5.png";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import "./AddContent.css";
import {
  BankOutlined,
  BarChartOutlined,
  HomeOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import company1 from "../../components/photos/company1.png";
import company2 from "../../components/photos/company2.png";
import company3 from "../../components/photos/company3.png";
const { Panel } = Collapse;
const AddContent = () => {
  const navigator = useNavigate();
  const [showSecondImage, setShowSecondImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState({});
  const reviews = [
    {
      text: ` Lead management is the process of capturing, tracking, and
                nurturing leads through various stages of the sales funnel until
                they convert into customers. Effective lead management helps
                businesses maximize their sales potential by organizing leads,
                improving communication.`,
      name: "Mr. John Doe",
      company: "Iconic Hospitals",
    },
    {
      text: `Lead management is the process of capturing, tracking, and
                nurturing leads through various stages of the sales funnel until
                they convert into customers. Effective lead management helps
                businesses maximize their sales potential by organizing leads,
                improving communication.`,
      name: "Ms. Jane Smith",
      company: "Health Plus",
    },
    {
      text: `Lead management is the process of capturing, tracking, and
      nurturing leads through various stages of the sales funnel until
      they convert into customers. Effective lead management helps
      businesses maximize their sales potential by organizing leads,
      improving communication.`,
      name: "Mr. David Lee",
      company: "MedCare Solutions",
    },
  ];

  const CompaniesDetails = [
    {
      src: company1,
      alt: "XYZ",
      name: "Mr.David",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
    {
      src: company2,
      alt: "XYZ",
      name: "Mr.David",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
    {
      src: company3,
      alt: "XYZ",
      name: "Mr. John Doe",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
  ];

  const handleButtonClick = () => {
    setShowSecondImage(true);
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % reviews.length);
  };
  const handleCollapseChange = (key) => {
    setExpandedKeys((prevKeys) => ({
      ...prevKeys,
      [key]: !prevKeys[key],
    }));
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? reviews.length - 2
        : (prevIndex - 2 + reviews.length) % reviews.length
    );
  };
  const handlepreviousClick = () => {
    setShowSecondImage(false);
  };

  const handlepayment = () => {
    navigator("/payment");
  };
  const handleReadMore = () => {
    navigator("/LMS");
  };
  const handleReadMoreForHAS = () => {
    navigator("/Appt");
  };
  return (
    <DefaultLayout>
      <Card
        style={{
          background: "#f9ede9",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Carousel
          arrows={true}
          infinite={true}
          autoplay
          autoplaySpeed={4000}
          slidesToShow={1}
          slidesToScroll={1}
          className="container"
        >
          <div>
            <Row
              align="middle"
              className="container"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <Col xs={24} sm={24} md={14} lg={10} xl={16}>
                <Typography.Title
                  style={{
                    fontSize: "3rem",
                    color: "black",
                    marginBottom: "2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Digi Lead Management System - DLMS that works for you.
                </Typography.Title>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "black",
                    marginBottom: "2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Streamline your sales process and convert more leads with
                  ease.
                </Typography>

                <Button
                  style={{
                    width: "10rem",
                    borderRadius: "4rem",
                    fontSize: "large",
                    height: "3rem",
                    background: "#2255a6",
                    color: "white",
                  }}
                  onClick={handlepayment}
                >
                  Get Started
                </Button>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <img src={Lmsicon} alt="LMS Icon" style={{ width: "100%" }} />
              </Col>
            </Row>
          </div>
          <div>
            <Row
              align="middle"
              className="container"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <Col xs={24} sm={24} md={14} lg={10} xl={16}>
                <Typography.Title
                  style={{
                    fontSize: "3rem",
                    color: "black",
                    marginBottom: "2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Healthcare Appointment System - HAS that works for you.
                </Typography.Title>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "black",
                    marginBottom: "2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Empowering health through seamless connections, one
                  appointment at a time.
                </Typography>

                <Button
                  style={{
                    width: "10rem",
                    borderRadius: "4rem",
                    fontSize: "large",
                    height: "3rem",
                    background: "#2255a6",
                    color: "white",
                  }}
                  onClick={handlepayment}
                >
                  Get Started
                </Button>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <img
                  src={doctorhomepage}
                  alt="Healthcare System Icon"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </Carousel>
      </Card>
      <Card
        style={{
          background: "white",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="container"
        >
          <h1 style={{ fontFamily: "Poppins", alignItems: "center" }}>
            Digi Lead Management System - DLMS
          </h1>
        </Row>
        <div className="container">
          <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>
            The Digi Lead Management System (DLMS) is a comprehensive platform
            designed to streamline and manage leads for businesses, particularly
            those with multiple brands. It is easy to use and understand,
            ensuring that users can navigate the system effortlessly and
            maximize their lead management efficiency.
          </Typography>
        </div>

        <Row className="container">
          <Col
            // xs={24}
            // sm={24}
            // md={12}
            // lg={12}
            // xl={12}
            lg={12}
            md={10}
            xl={12}
            xs={24}
            sm={10}
            style={{
              display: "flex",
              marginTop: "2rem",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            {/* <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                textAlign: "center",
              }}
            > */}
            <img
              src={DashBoard1}
              alt="LeadList Icon"
              style={{
                width: "100%",
              }}
            />
            {/* </Card> */}

            {/* <Button
              style={{
                width: "10rem",
                borderRadius: "4rem",
                fontSize: "large",
                height: "3rem",
                marginTop: "1rem",
                background: "#2154a6",
                color: "white",
              }}
              onClick={handleReadMore}
            >
              Read More
            </Button> */}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={12}
            xl={10}
            style={{
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
              }}
              className="container"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={LeadCapture}
                  alt="LeadCapture Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Lead Capture
                  <br />
                  and Tracking
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={MultiChannelSupport}
                  alt="MultiChannelSupport Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Multi-Channel
                  <br /> Support
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={ComprehensiveDashboards}
                  alt="ComprehensiveDashboards Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Comprehensive
                  <br />
                  Dashboards
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={EasytoUse}
                  alt="EasytoUse Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Easy to
                  <br />
                  Use
                </Typography>
              </div>
            </Row>
          </Col>
        </Row>

        <h2 style={{ alignItems: "center", textAlign: "center" }}>
          Who will use Digi Lead Management Software?
        </h2>
        <Row
          style={{
            justifyContent: "space-around",
          }}
          className="container"
        >
          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <HomeOutlined
                  style={{
                    fontSize: "4rem",
                    color: "black",
                  }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Real Estate
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BankOutlined
                  style={{
                    fontSize: "4rem",
                    // color: "#a0abab",
                    color: "black",
                  }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Financial Advisors
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <LineChartOutlined
                  style={{
                    fontSize: "4rem",
                    color: "black",
                  }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Businesses
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BarChartOutlined
                  style={{
                    fontSize: "4rem",
                    color: "black",
                  }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Sales Teams
              </h4>
            </Card>
          </Col>
          <Col
            lg={24}
            xl={24}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center", // Optional for vertical centering
            }}
          >
            <Button
              style={{
                width: "10rem",
                borderRadius: "4rem",
                fontSize: "large",
                height: "3rem",
                marginTop: "1rem",
                background: "#2154a6",
                color: "white",
              }}
              onClick={handleReadMore}
            >
              Read More
            </Button>
          </Col>
        </Row>
      </Card>

      <Card
        style={{
          background: "#f9ede9",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="container"
        >
          <h1 style={{ fontFamily: "Poppins", alignItems: "center" }}>
            Healthcare Appointment System(HAS)
          </h1>
        </Row>
        <div>
          <Typography
            className="container"
            style={{ fontSize: "14px", fontFamily: "Poppins" }}
          >
            A healthcare appointment system streamlines scheduling for patients
            and medical staff, enabling easy booking, rescheduling, and
            cancellations. It reduces waiting times, enhances patient
            management, and improves overall healthcare service efficiency
          </Typography>
        </div>
        <Row className="container">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              display: "flex",
              marginTop: "2rem",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                <img
                  src={LeadCapture}
                  alt="LeadCapture Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Dynamic Appointment
                  <br /> Booking
                </Typography>
              </div>
              <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                <img
                  src={MultiChannelSupport}
                  alt="MultiChannelSupport Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Automated
                  <br />
                  Reminders
                </Typography>
              </div>
              <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                <img
                  src={ComprehensiveDashboards}
                  alt="ComprehensiveDashboards Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Doctor Avaiability
                  <br /> Management
                </Typography>
              </div>
              <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                <img
                  src={EasytoconnectHIS}
                  alt="EasytoconnectHIS Icon"
                  style={{
                    width: "180px",
                    height: "180px",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Easy to connect HIS
                  <br />
                  (hospital information system)
                </Typography>
              </div>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={ApptDashbord}
              alt="LeadList Icon"
              style={{
                width: "100%",
              }}
            />
          </Col>
        </Row>

        <h2 style={{ alignItems: "center", textAlign: "center" }}>
          Who will use Healthcare Appointment System - HAS ?
        </h2>
        <Row
          style={{
            justifyContent: "space-around",
          }}
          className="container"
        >
          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={hospitalicon}
                  alt="hospitalicon"
                  style={{ width: "100px", height: "100%" }}
                />
              </div>
              <h4
                style={{
                  display: "flex", // Use flex for centering
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Hospital
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Patientsicons}
                  alt="Patientsicons"
                  style={{ width: "100px", height: "100%" }}
                />
              </div>

              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Doctor's
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={Pharmaciesicon}
                  alt="Pharmaciesicon"
                  style={{ width: "100px", height: "100%" }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Pharmacies
              </h4>
            </Card>
          </Col>

          <Col
            lg={4}
            md={10}
            xl={5}
            xs={24}
            sm={10}
            style={{ marginTop: "2%" }}
          >
            <Card
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={AdministrativeStaff}
                  alt="AdministrativeStaff"
                  style={{ width: "100px", height: "100%" }}
                />
              </div>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Office Staff
              </h4>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={24} lg={24} xl={24} sm={24}>
            <Row gutter={16} style={{ width: "100%" }}>
              <Col
                span={6}
                lg={12}
                md={12}
                xl={6}
                xs={24}
                sm={12}
                style={{ marginTop: "2%" }}
              ></Col>
            </Row>
          </Col>

          <Col
            xl={24}
            sm={24}
            xs={24}
            lg={24}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2px",
            }}
          >
            <Button
              style={{
                width: "10rem",
                borderRadius: "4rem",
                fontSize: "large",
                height: "3rem",
                marginBottom: "3rem",
                background: "#c54927",
                color: "white",
              }}
              onClick={handleReadMoreForHAS}
            >
              Read More
            </Button>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col md={24} sm={24} lg={24} xl={24} xs={24}>
          <Card
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",

                fontFamily: "Poppins",
              }}
            >
              Transform Your Lead Management
            </h1>
            <Typography
              className="container"
              style={{ fontSize: "14px", fontFamily: "Poppins" }}
            >
              Discover how our powerful lead management software streamlines
              your sales process, enhances team collaboration, and boosts
              conversion rates. Experience the future of lead management today!
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#2154a6",
                  color: "white",
                  height: "3rem",
                  fontWeight: "600",
                  width: "12rem",
                  borderRadius: "2.5rem",
                }}
              >
                Request a Demo
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Card style={{ background: "#f9ede9" }}>
        <Row gutter={[16, 16]} justify="center">
          <Col span={24}>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Poppins",
              }}
            >
              What our customers say
            </h1>
          </Col>

          {/* <Col xs={24} sm={12} md={10}>
            <Card
              className="container"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                height: "auto",
              }}
            >
              <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>
                {reviews[currentIndex].text}
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  marginTop: "1rem",
                }}
              >
                {reviews[currentIndex].name}
              </Typography>
              <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>
                {reviews[currentIndex].company}
              </Typography>
            </Card>
          </Col> */}

          <Col xs={24} sm={10} md={16} lg={16} xl={16}>
            <Carousel
              arrows={true}
              infinite={true}
              autoplay
              autoplaySpeed={4000}
              slidesToShow={1}
              slidesToScroll={1}
              className="container"
            >
              {CompaniesDetails.map((Details, index) => (
                <div key={index}>
                  {/* <Row align="middle" className="container"> */}
                  <Row gutter={[20, 20]} className="container">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Card>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", // Vertically align content
                          }}
                        >
                          <Card style={{ marginRight: "20px" }}>
                            <img
                              src={Details.src}
                              alt={Details.alt}
                              className="company1"
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "cover",
                              }} // Adjust image size
                            />
                          </Card>
                          <div>
                            <h5
                              style={{
                                margin: 0,
                                padding: 0,
                                marginBottom: "5px",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {Details.title}
                            </h5>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                margin: 0,
                                padding: 0,
                                lineHeight: 1.5,
                              }}
                            >
                              {Details.name}
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          style={{
                            fontSize: "14px",
                            color: "black",
                            marginTop: "1rem",
                            marginBottom: "2rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {Details.description}
                        </Typography>
                      </Card>
                    </Col>

                    {/* Second Card */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      {CompaniesDetails[index + 1] && (
                        <Card>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Card style={{ marginRight: "20px" }}>
                              <img
                                src={CompaniesDetails[index + 1].src}
                                alt={CompaniesDetails[index + 1].alt}
                                className="company1"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                              />
                            </Card>
                            <div>
                              <h5
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginBottom: "5px",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                {CompaniesDetails[index + 1].title}
                              </h5>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  margin: 0,
                                  padding: 0,
                                  lineHeight: 1.5,
                                }}
                              >
                                {CompaniesDetails[index + 1].name}
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "black",
                              marginTop: "1rem",
                              marginBottom: "2rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {CompaniesDetails[index + 1].description}
                          </Typography>
                        </Card>
                      )}
                    </Col>
                  </Row>
                  {/* </Row> */}
                </div>
              ))}
            </Carousel>
          </Col>

          {/* Right navigation button */}
        </Row>
      </Card>
      <Row>
        <Col md={18} sm={18} lg={24} xl={24} xs={24}>
          <Card
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Poppins",
              }}
            >
              Frequently Asked Questions
            </h1>
            <div
              style={{
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Collapse
                size="small"
                style={{ marginTop: "1.5rem", width: "85%" }}
                onChange={() => handleCollapseChange("panel1")}
                expandIconPosition="right"
                expandIcon={() => null}
                showArrow={false}
              >
                <Panel
                  key="panel1"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                  }}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>What is lead management?</h2>
                      {expandedKeys["panel1"] ? (
                        <MinusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      ) : (
                        <PlusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      )}
                    </div>
                  }
                >
                  <Typography
                    style={{ fontSize: "14px", fontFamily: "Poppins" }}
                  >
                    Lead management is the process of capturing, tracking, and
                    nurturing leads through various stages of the sales funnel
                    until they convert into customers. Effective lead management
                    helps businesses maximize their sales potential by
                    organizing leads and improving communication.
                  </Typography>
                </Panel>
              </Collapse>

              <Collapse
                size="small"
                style={{ marginTop: "1.5rem", width: "85%" }}
                onChange={() => handleCollapseChange("panel2")}
                expandIconPosition="right"
                expandIcon={() => null}
                showArrow={false}
              >
                <Panel
                  key="panel2"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                  }}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>What is the purpose of the LMS in healthcare?</h2>
                      {expandedKeys["panel2"] ? (
                        <MinusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      ) : (
                        <PlusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      )}
                    </div>
                  }
                >
                  <Typography
                    style={{ fontSize: "14px", fontFamily: "Poppins" }}
                  >
                    The LMS helps healthcare providers manage patient leads
                    efficiently, track inquiries, and streamline communication,
                    ultimately improving patient engagement and appointment
                    scheduling.
                  </Typography>
                </Panel>
              </Collapse>

              <Collapse
                size="small"
                style={{ marginTop: "1.5rem", width: "85%" }}
                onChange={() => handleCollapseChange("panel3")}
                expandIconPosition="right"
                expandIcon={() => null}
                showArrow={false}
              >
                <Panel
                  key="panel3"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                  }}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>How does the appointment scheduling feature work?</h2>
                      {expandedKeys["panel3"] ? (
                        <MinusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      ) : (
                        <PlusOutlined
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      )}
                    </div>
                  }
                >
                  <Typography
                    style={{ fontSize: "14px", fontFamily: "Poppins" }}
                  >
                    Users can view available time slots, select a preferred date
                    and time, and book appointments directly through the app.
                    Confirmation notifications will be sent via email.
                  </Typography>
                </Panel>
              </Collapse>
            </div>
          </Card>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default AddContent;
