import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddContent from "./views/AddContent/AddContent";
import Payment from "./views/Payment/Payment";
import Leadsmanagemnets from "./views/LMS/Leadsmanagemnets";
import Appt from "./views/Appt/Appt";
import Integration from "./views/Integration/Integration";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AddContent />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/LMS" element={<Leadsmanagemnets />} />
        <Route path="/WhatsApp" element={<Integration />} />

        <Route path="/Appt" element={<Appt />} />
      </Routes>
    </Router>
  );
}

export default App;
