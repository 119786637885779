import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Flex,
  Row,
  Typography,
} from "antd";

import CreateLead from "../../components/photos/CreateLead2.png";
import LeadList from "../../components/photos/LeadList2.png";
import Adduser from "../../components/photos/AddUser.png";
import UserList from "../../components/photos/UserList.png";
import React, { useEffect, useRef, useState } from "react";

import AddBusiness from "../../components/photos/AddBusiness.png";
import LeadSource from "../../components/photos/LeadSource.png";
import LeadStatus from "../../components/photos/LeadStatus.png";
import Appt from "../../components/photos/Appt.png";
import AddLocation from "../../components/photos/AddLocation.png";
import Addservices from "../../components/photos/Addservices.png";
import ApptList from "../../components/photos/apptListLMS.png";
import LeadCaptureandTracking from "../../components/photos/icon-1.png";
import MultiChannelSupport from "../../components/photos/icon-2.png";
import ComprehensiveDashboards from "../../components/photos/icon-3.png";
import EasytoUse from "../../components/photos/icon-4.png";
import company1 from "../../components/photos/company1.png";
import company2 from "../../components/photos/company2.png";
import company3 from "../../components/photos/company3.png";
import "./Leadsmanagemnets.css";
import {
  LeftOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
const { Panel } = Collapse;
const Leadsmanagemnets = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState({});

  const handleCollapseChange = (key) => {
    setExpandedKeys((prevKeys) => ({
      ...prevKeys,
      [key]: !prevKeys[key],
    }));
  };
  const images = [
    // {
    //   src: Login,
    //   alt: "Login Icon",
    //   title: "Login",
    //   description: "You can login using your email and password",
    // },
    {
      src: AddBusiness,
      alt: "Add Business Icon",
      title: "Add Business",
      description: "Add a new business to the system",
    },
    {
      src: LeadStatus,
      alt: "Lead Status Icon",
      title: "Lead Status",
      description: "View and update the status of leads",
    },
    {
      src: AddLocation,
      alt: "Add Location Icon",
      title: "Add Location",
      description: "Add a new location for your business or lead",
    },
    {
      src: LeadSource,
      alt: "Lead Source Icon",
      title: "Lead Source",
      description: "Track the source of your leads",
    },
    {
      src: Addservices,
      alt: "Add Services Icon",
      title: "Add Services",
      description: "Add services to your business",
    },
    {
      src: CreateLead,
      alt: "Create Lead Icon",
      title: "Create Lead",
      description: "Create a new lead in the system",
    },
    {
      src: LeadList,
      alt: "Lead List Icon",
      title: "Lead List",
      description: "View the list of all leads",
    },
    {
      src: Adduser,
      alt: "Add User Icon",
      title: "Add User",
      description: "Add new users to the system",
    },
    {
      src: UserList,
      alt: "User List Icon",
      title: "User List",
      description: "View and manage all users in the system",
    },
    {
      src: Appt,
      alt: "Appt Icon",
      title: "Appointment",
      description: "Schedule appointments with users or leads",
    },
    {
      src: ApptList,
      alt: "Appt List Icon",
      title: "Appointment List",
      description: "View and manage all appointments",
    },
  ];
  const CompaniesDetails = [
    {
      src: company1,
      alt: "XYZ",
      name: "Mr.David",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
    {
      src: company2,
      alt: "XYZ",
      name: "Mr.David",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
    {
      src: company3,
      alt: "XYZ",
      name: "Mr. John Doe",
      title: "EduTech LMS", // the name of the LMS platform
      description:
        "EduTech LMS is an all-in-one platform designed to help educational institutions and businesses manage their learning programs efficiently. With powerful tools to create, deliver, and track courses, it enables instructors to provide personalized learning experiences. The system also allows real-time monitoring of learner progress, detailed analytics, and seamless communication between students and instructors. Whether you're managing certifications, tracking performance, or offering collaborative learning opportunities, EduTech LMS provides the flexibility and scalability needed to support diverse educational needs.",
    },
  ];
  {
    /* "#d3e3fb" */
    // "#d9eefbc9"
  }
  return (
    <>
      <DefaultLayout>
        <Card style={{ background: "#d9eefbc9", marginTop: "0.5rem" }}>
          <Row justify="center">
            <Col xs={24} sm={10} md={16} lg={16} xl={16}>
              <Carousel
                arrows={true}
                infinite={true}
                autoplay
                autoplaySpeed={3000}
                slidesToShow={1}
                slidesToScroll={1}
                className="container"
              >
                {images.map((image, index) => (
                  <div key={index}>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center" }}
                    >
                      <Col xs={24} sm={24} md={14} lg={10} xl={11}>
                        <Typography.Title
                          style={{
                            fontSize: "3rem",
                            color: "black",
                            marginBottom: "2rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {image.title}
                        </Typography.Title>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "black",
                            marginBottom: "2rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {image.description}
                        </Typography>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={13}
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="imgClass"
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Card>
        <Card style={{ background: "white" }}>
          <Row justify="center">
            <Col xs={24} sm={10} md={10} xl={16}>
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col xl={10} lg={8} md={10} sm={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={LeadCaptureandTracking}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        margin: "0 auto", // Center the image
                      }}
                      alt="Lead Capture and Tracking"
                    />
                  </div>
                </Col>
                <Col xl={14} lg={16} md={16} sm={24} xs={24}>
                  <div>
                    <h3>Lead Capture and Tracking</h3>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        marginTop: "1rem",
                        textAlign: "justify",
                      }}
                    >
                      In a Lead Management System (LMS), Lead Capture and
                      Tracking are critical processes that ensure no potential
                      opportunity is lost. Leads are captured from various
                      sources like websites, email campaigns, and social media,
                      then stored centrally for easy access and organization.
                    </Typography>
                  </div>
                </Col>
                <div style={{ backgroundColor: "#f0f0f0c7", padding: "1rem" }}>
                  <Row gutter={[16, 16]}>
                    <Col xl={10} lg={13} md={10} sm={24} xs={24}>
                      <div>
                        <h3>Multi-Channel Support</h3>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            marginTop: "1rem",
                            textAlign: "justify",
                          }}
                        >
                          In the context of Multi-Channel Support for a Learning
                          Management System (LMS), it refers to the capability
                          of the platform to handle multiple businesses or
                          organizations, each with its own specific needs and
                          configurations.
                        </Typography>
                      </div>
                    </Col>
                    <Col xl={14} lg={11} md={10} sm={24} xs={24}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={MultiChannelSupport}
                          style={{
                            width: "100%",
                            marginTop: "1%",
                            display: "flex",
                            justifyContent: "center",

                            maxWidth: "250px",
                            margin: "0 auto", // Center the image
                          }}
                          alt="Lead Capture and Tracking"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* <Col xl={14} lg={8} md={10} sm={24} xs={24}>
                  <div>
                    <h3>Multi-Channel Support</h3>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        marginTop: "1rem",
                        textAlign: "justify",
                      }}
                    >
                      In the context of Multi-Channel Support for a Learning
                      Management System (LMS), it refers to the capability of
                      the platform to handle multiple businesses or
                      organizations, each with its own specific needs and
                      configurations.
                    </Typography>
                  </div>
                </Col>
                <Col xl={10} lg={12} md={10} sm={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={MultiChannelSupport}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        margin: "0 auto", // Center the image
                      }}
                      alt="Lead Capture and Tracking"
                    />
                  </div>
                </Col> */}
                <Col xl={10} lg={8} md={10} sm={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={ComprehensiveDashboards}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        margin: "0 auto", // Center the image
                      }}
                      alt="Lead Capture and Tracking"
                    />
                  </div>
                </Col>
                <Col xl={14} lg={16} md={10} sm={24} xs={24}>
                  <div>
                    <h3>Comprehensive Dashboards</h3>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        marginTop: "1rem",
                        textAlign: "justify",
                      }}
                    >
                      Comprehensive Dashboards in an LMS offer a centralized
                      view of important learning data, including course
                      completion rates, user progress, and performance metrics.
                      With the ability to filter the dashboard based on dates,
                      users can view and analyze data for specific time frames
                      such as daily, weekly, monthly, or custom date ranges.
                    </Typography>
                  </div>
                </Col>
                <div style={{ backgroundColor: "#f0f0f0c7", padding: "1rem" }}>
                  <Row gutter={[16, 16]}>
                    <Col xl={10} lg={13} md={10} sm={24} xs={24}>
                      <div>
                        <h3>Easy to Use</h3>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            marginTop: "1rem",
                            textAlign: "justify",
                          }}
                        >
                          Easy to Use in an LMS refers to a user-friendly
                          interface that simplifies navigation for
                          administrators and learners. The system is intuitive,
                          requiring minimal training with clear menus and easy
                          access to features like course enrollment, progress
                          tracking, and content management, ensuring a smooth
                          experience for users of all technical skill levels.
                        </Typography>
                      </div>
                    </Col>
                    <Col xl={14} lg={11} md={10} sm={24} xs={24}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={EasytoUse}
                          style={{
                            width: "100%",
                            maxWidth: "250px",
                            margin: "0 auto", // Center the image
                          }}
                          alt="Lead Capture and Tracking"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <Col xl={14} lg={8} md={10} sm={24} xs={24}>
                  <div>
                    <h3>Easy to Use</h3>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        marginTop: "1rem",
                        textAlign: "justify",
                      }}
                    >
                      Easy to Use in an LMS refers to a user-friendly interface
                      that simplifies navigation for administrators and
                      learners. The system is intuitive, requiring minimal
                      training with clear menus and easy access to features like
                      course enrollment, progress tracking, and content
                      management, ensuring a smooth experience for users of all
                      technical skill levels.
                    </Typography>
                  </div>
                </Col>
                <Col xl={10} lg={12} md={10} sm={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={EasytoUse}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        margin: "0 auto", // Center the image
                      }}
                      alt="Lead Capture and Tracking"
                    />
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Card>
        <Card style={{ background: "#d9eefbc9" }}>
          <Row justify="center">
            <Col xs={24} sm={10} md={16} lg={16} xl={16}>
              {/* <Carousel
                arrows={true}
                infinite={true}
                autoplay
                autoplaySpeed={4000}
                slidesToShow={1}
                slidesToScroll={1}
                className="container"
              >
                {CompaniesDetails.map((Details, index) => (
                  <div key={index}>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center", gap: "8px" }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          What our customers say
                        </h1>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                        <Card>
                          <div
                            style={{
                              display: "flex",
                              // flexDirection: "column",
                              // alignItems: "center",
                            }}
                          >
                            <Card>
                              <img
                                src={Details.src}
                                alt={Details.alt}
                                className="company1"
                              />
                            </Card>
                            <div>
                              <h5
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginBottom: "5px", // Add space between title and name
                                }}
                              >
                                {Details.title}
                              </h5>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  margin: 0,
                                  padding: 0,
                                  lineHeight: 1.5, // Adjust line height for better spacing
                                }}
                              >
                                {Details.name}
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "black",
                              marginBottom: "2rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {Details.description}
                          </Typography>
                        </Card>
                      </Col>
                      {CompaniesDetails[index + 1] && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                          <Card>
                            <div
                              style={{
                                display: "flex",
                                // flexDirection: "column",
                                // alignItems: "center",
                              }}
                            >
                              <Card>
                                <img
                                  src={CompaniesDetails[index + 1].src}
                                  alt={CompaniesDetails[index + 1].alt}
                                  className="company1"
                                />
                              </Card>
                              <div>
                                <h5
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    marginBottom: "5px", // Add space between title and name
                                  }}
                                >
                                  {CompaniesDetails[index + 1].title}
                                </h5>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 1.5, // Adjust line height for better spacing
                                  }}
                                >
                                  {CompaniesDetails[index + 1].name}
                                </Typography>
                              </div>
                            </div>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "black",
                                marginBottom: "2rem",
                                fontFamily: "Poppins",
                                // textAlign: "center",
                              }}
                            >
                              {CompaniesDetails[index + 1].description}
                            </Typography>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Carousel> */}
              <Col span={24}>
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                  }}
                >
                  What our customers say
                </h1>
              </Col>
              <Carousel
                arrows={true}
                infinite={true}
                autoplay
                autoplaySpeed={4000}
                slidesToShow={1}
                slidesToScroll={1}
                className="container"
              >
                {CompaniesDetails.map((Details, index) => (
                  <div key={index}>
                    <Row
                      align="middle"
                      className="container"
                      style={{ flex: 1, justifyContent: "center", gap: "8px" }}
                    >
                      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <Card>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row", // Keep flex direction row
                              alignItems: "center", // Align items vertically centered
                            }}
                          >
                            <Card style={{ marginRight: "20px" }}>
                              <img
                                src={Details.src}
                                alt={Details.alt}
                                className="company1"
                              />
                            </Card>
                            <div>
                              <h5
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginBottom: "5px",
                                }}
                              >
                                {Details.title}
                              </h5>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  margin: 0,
                                  padding: 0,
                                  lineHeight: 1.5,
                                }}
                              >
                                {Details.name}
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "black",
                              marginBottom: "2rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {Details.description}
                          </Typography>
                        </Card>
                      </Col>
                      {CompaniesDetails[index + 1] && (
                        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                          <Card>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Card style={{ marginRight: "20px" }}>
                                <img
                                  src={CompaniesDetails[index + 1].src}
                                  alt={CompaniesDetails[index + 1].alt}
                                  className="company1"
                                />
                              </Card>
                              <div>
                                <h5
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    marginBottom: "5px",
                                  }}
                                >
                                  {CompaniesDetails[index + 1].title}
                                </h5>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 1.5,
                                  }}
                                >
                                  {CompaniesDetails[index + 1].name}
                                </Typography>
                              </div>
                            </div>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "black",
                                marginBottom: "2rem",
                                fontFamily: "Poppins",
                              }}
                            >
                              {CompaniesDetails[index + 1].description}
                            </Typography>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Card>

        <Card style={{ background: "white" }}>
          <Row gutter={[35, 35]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Frequently Asked Questions
              </h1>
              <div
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel1")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel1"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>What is a Lead Management System (LMS)?</h2>
                        {expandedKeys["panel1"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      A Lead Management System (LMS) is a software solution that
                      helps businesses capture, organize, track, and manage
                      leads throughout the sales funnel. It enables businesses
                      to streamline lead handling, improve communication with
                      potential customers, and enhance the chances of converting
                      leads into sales.
                    </Typography>
                  </Panel>
                </Collapse>

                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel2")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel2"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2> Is my data secure in an LMS?</h2>
                        {expandedKeys["panel2"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      Most modern LMS platforms have robust security measures in
                      place to protect your data, including data encryption,
                      secure access controls, and regular security updates. It’s
                      recommended to verify the security features of your LMS
                      provider to ensure compliance with industry standards and
                      data protection regulations.
                    </Typography>
                  </Panel>
                </Collapse>

                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel3")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel3"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>How does an LMS handle lead scoring?</h2>
                        {expandedKeys["panel3"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      An LMS can assign a score to each lead based on various
                      criteria, such as engagement level, lead source, or
                      demographic data. This scoring system helps sales teams
                      identify and prioritize high-quality leads, improving the
                      efficiency of the sales process and focusing on leads more
                      likely to convert.
                    </Typography>
                  </Panel>
                </Collapse>
                <Collapse
                  size="small"
                  style={{ marginTop: "1.5rem", width: "70%" }}
                  onChange={() => handleCollapseChange("panel3")}
                  expandIconPosition="right"
                  expandIcon={() => null}
                  showArrow={false}
                >
                  <Panel
                    key="panel3"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "white",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2>Is your LMS easy to use for beginners?</h2>
                        {expandedKeys["panel3"] ? (
                          <MinusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{ fontSize: "20px", color: "black" }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Typography
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      An LMS can assign a score to each lead based on various
                      Yes, our LMS is designed with a user-friendly interface
                      that’s intuitive and easy to navigate, even for beginners.
                      The dashboard is straightforward, and we provide helpful
                      guides to make onboarding quick and simple.
                    </Typography>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Card>
      </DefaultLayout>
    </>
  );
};
export default Leadsmanagemnets;
